import React from 'react';

const TestimonialCard = (props) => {
    return (
        <>
            <div className='p-3 bg-white mt-3' style={{ border: '2px solid rgba(230, 230, 230, 1)', borderRadius: '28px' }}>

                <div className='align-items-center d-flex'>
                    <img src={props.img} className='rounded-5 ' width={50} height={50} alt="image not found" />
                    <div className='ms-3'>
                        <p className="font-16 fw-bold mb-0 text-uppercase" style={{ color: 'rgba(0, 0, 0, 1)' }}>{props.head}</p>
                        <p className="font-16 mb-0" style={{ color: 'rgba(0, 0, 0, 0.7)' }}>{props.phara}</p>

                    </div>
                </div>
                <p className="font-16 mb-0 mt-3" style={{ color: 'rgba(0, 0, 0, 0.7)' }}>{props.pharaTwo}</p>

            </div>
        </>
    );
}

export default TestimonialCard;
