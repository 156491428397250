import React from "react";
import { Route, Routes } from "react-router-dom";
//Components
import ProtectedRoute from "./protectedRoute";
import PlansPricing from "../pages/planPricing/PlansPricing";
import About from "../pages/web/About";
import Blog from "../pages/web/Blog";
import BlogPost from "../pages/web/BlogPost";
import Contact from "../pages/web/Contact";
import CookiesPolicy from "../pages/web/CookiesPolicy";
import ErrorPage from "../pages/web/ErrorPage";
import PrivacyPolicy from "../pages/web/PrivacyPolicy";
import TermCondition from "../pages/web/TermCondition";
import Testimonials from "../pages/web/Testimonials";
import SquarePosIntegration from "../pages/features/SquarePosIntegration";
import GuesMesseges from "../pages/features/GuesMesseges";
import Queue from "../pages/features/Queue";
import NewHome from "../pages/NewHome";
import ContactSale from "../pages/web/ContactSale";
import Login from "../pages/web/Login";

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<NewHome />} />
          <Route path="/PlanPricing" element={<PlansPricing />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogPost" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contactSale" element={<ContactSale />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/termCondition" element={<TermCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookiesPolicy" element={<CookiesPolicy />} />
          <Route path="/feature/queue-management" element={<Queue />} />
          <Route path="/feature/guest-messages" element={<GuesMesseges />} />
          <Route path="/feature/square-pos-integration" element={<SquarePosIntegration />} />
        </Route>
      </Routes>
    </>
  );
}

export default AppRoutes;
