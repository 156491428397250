import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Navbar from "../../Components/Navbar";
import ScrollToTop from "../../Components/ScrollToTop";
import Footer from "../../Components/about/Footer";
import MarketingCampaign from "../../assests/MarketingCampaigns.png";
const PlansPricing = () => {
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container pb-4">
        <div className="container text-center mt-5">
          {/* <h6
            className="fw-bold text-uppercase "
            style={{ color: "rgba(74, 58, 255, 1)" }}
          >
            simple, transparent pricing
          </h6> */}
          <h1 className="font-48 fw-bold blue-text">
            {/* Ready to Get Started? */}
            For big and small businesses{" "}
          </h1>
          <p className="text-secondary dm  font-18 blue-text fw-medium ">
            5 minute sign-up - Try Starter features free for 7 days - No credit
            card reequired
          </p>
          <br />
          <Link to="https://console.timelink.au/auth/signup">
            <button
              type="button"
              className="btn-blue font-20 rounded-4 fw-bold px-5"
              style={{ padding: "12px" }}
            >
              Start your free trial
            </button>
          </Link>
        </div>
        {/* <div className="pricing-switch d-flex justify-content-center ">
          <h5 className="fw-medium">Monthly</h5>
          <div className="container-switch mx-3">
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox"
              checked={terms === 1}
              onChange={(e) => setTerms(e.target.checked ? 1 : 0)}
            />
            <label className="switch" htmlFor="checkbox">
              <span className="slider" />
            </label>
          </div>
          <h5 className="fw-medium">Yearly</h5>
        </div>
        <div className="text-center mt-4">
          <span
            className="rounded-5 py-1 px-2 font-14 position-relative "
            style={{ backgroundColor: "rgba(219, 230, 255, 1)" }}
          >
            {terms == 0 ? "Save " : "Saving up to"} 30%
            <span className="saving-curve">
              <img src={curve} width="55" alt="w8" />
            </span>
          </span>
        </div> */}
        <br className="d-lg-none d-block" />
        <br className="d-lg-none d-block" />
        <div className="row mt-lg-5  mt-2 dm">
          <div className="col-lg-4  col-12 mt-lg-4">
            <div className="growthEven h-100 p-4 d-flex flex-column justify-content-between ">
              <div>
                <div className="text-center poppins ">
                  {/* <p className="gray fw-medium font-18 mb-0 ">
                    Just enough.
                    </p> */}
                  <h1 className=" ">Starter</h1>
                  <p className=" my-2">For small businesses </p>
                  <div className="d-flex align-items-center justify-content-center black">
                    <sub className=" font-15 fw-semibold mt-2 ">$</sub>
                    <h1 className="fw-bold font-56 mb-0">46.75</h1>
                    <sub className=" font-15 fw-semibold mt-2">/mo</sub>
                  </div>
                  <p className="mb-1 black">Billed annually </p>
                  <p className=" black">
                    or<span className="fw-bold "> $55 </span>
                    month-to-month{" "}
                  </p>
                </div>
                <div className="text-center">
                  <Link to="/contactSale">
                    <Button
                      type="button"
                      className="btn-blue fw-normal flex-shrink-0 w-auto  mb-2 p-2 py-0 rounded-5 font-16 px-4 text-capitalize "
                      style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}
                    >
                      Contact Sales{" "}
                    </Button>
                  </Link>
                </div>
                <p className="mt-2 black font-18 lato">Key Features:</p>
                <div className="d-flex align-items-center text-dark-blue mb-3">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black" />
                  <div className="d-flex align-items-center gap-2">
                    <p className="black mb-0">Digital Notification System</p>
                    <RiErrorWarningFill
                      className="text-gray  flex-shrink-0"
                      size={16}
                    />
                  </div>
                </div>
                <div className="d-flex text-dark-blue">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black" />
                  <p className="black">Basic Analytics</p>
                </div>
                <div className="d-flex text-dark-blue">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black" />
                  <p className="black">750 SMS Included</p>
                </div>
                <div className="d-flex text-dark-blue">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black" />
                  <p className="black">
                    Timelink Connect Device Included ($74.99 Value)
                  </p>
                </div>
                <Link className="compare-link">Compare features.</Link>
              </div>
              {/* <div className=" text-center">
                <Button className=" flex-shrink-0  text-capitalize w-100 py-3 rounded-5 font-16">
                  Get started
                </Button>
              </div> */}
            </div>
          </div>

          <div className="col-lg-4  col-12  mt-lg-4 mt-4 text-white">
            <div className="growthEven h-100 p-4 d-flex flex-column justify-content-between ">
              <div className="p-1">
                {/* <div className="text-end">

                  <div className="p-2 d-inline px-3 font-14 rounded-5" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', height: 'fit-content' }}>Popular</div>
                </div> */}
                <div className="text-center poppins">
                  <h1 className=" ">Essential</h1>
                  <p className=" my-2">SME’s looking to grow</p>
                  <div className="d-flex align-items-center justify-content-center black">
                    <sub className=" font-15 fw-semibold mt-2 ">$</sub>
                    <h1 className="fw-bold font-56 mb-0">72</h1>
                    <sub className=" font-15 fw-semibold mt-2">/mo</sub>
                  </div>
                  <p className="mb-1 black">Billed annually </p>
                  <p className=" black">
                    or<span className="fw-bold "> $90 </span>
                    month-to-month{" "}
                  </p>
                </div>
                <div className="text-center">
                  <Link to="/contactSale">
                    <Button
                      type="button"
                      className="btn-blue fw-normal flex-shrink-0 w-auto  mb-2 p-2 py-0 rounded-5 font-16 px-4 text-capitalize "
                      style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}
                    >
                      Contact Sales{" "}
                    </Button>
                  </Link>
                </div>
                <p className="mt-2 black font-18 lato">Includes: </p>
                <div className="d-flex ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">Digital Notification System</p>
                </div>
                <div className="d-flex ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">Comprehensive Analytics & Insights</p>
                </div>
                <div className="d-flex ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">1250 SMS Included</p>
                </div>

                <div className="d-flex ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">
                    Timelink Connect Device Included ($74.99 Value)
                  </p>
                </div>
                <div className="d-flex ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <div className="d-flex align-items-center gap-2">
                    <p className="black">
                      1 <strong>FREE</strong> Accumulating Marketing Credits{" "}
                      <strong>EACH</strong> Month ($75 Value)
                    </p>
                    <RiErrorWarningFill
                      className="text-gray flex-shrink-0"
                      size={16}
                    />
                  </div>
                </div>
                <Link className="compare-link">Compare features.</Link>
              </div>
              {/* <div className=" text-center">
                <Button className=" flex-shrink-0 text-capitalize w-100  rounded-5 font-16">
                  Get started
                </Button>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4  col-12 mt-lg-4 mt-4">
            <div className="growthEven h-100 p-4 d-flex flex-column justify-content-between ">
              <div>
                <div className="text-center poppins">
                  <h1 className=" ">Growth</h1>
                  <p className=" my-2">For scaling teams</p>
                  <div className="d-flex align-items-center justify-content-center black">
                    <sub className=" font-15 fw-semibold mt-2 ">$</sub>
                    <h1 className="fw-bold font-56 mb-0">86.25</h1>
                    <sub className=" font-15 fw-semibold mt-2">/mo</sub>
                  </div>
                  <p className="mb-1 black">Billed annually </p>
                  <p className=" black">
                    or<span className="fw-bold "> $115 </span>
                    month-to-month{" "}
                  </p>
                </div>
                <div className="text-center">
                  <Link to="/contactSale">
                    <Button
                      type="button"
                      className="btn-blue fw-normal flex-shrink-0 w-auto  mb-2 p-2 py-0 rounded-5 font-16 px-4 text-capitalize "
                      style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}
                    >
                      Contact Sales{" "}
                    </Button>
                  </Link>
                </div>
                <p className="mt-2 black font-18 lato">Includes: </p>
                <div className="d-flex  black">
                  <IoMdCheckmark className="me-2 flex-shrink-0 mt-1 black" />
                  <p className="black ">Digital Notification System</p>
                </div>
                <div className="d-flex black ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 mt-1 black" />
                  <p className="black ">Comprehensive Analytics & Insights</p>
                </div>
                <div className="d-flex black ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 mt-1  black" />
                  <p className="black ">1250 SMS Included</p>
                </div>
                <div className="d-flex black  ">
                  <IoMdCheckmark className="me-2 flex-shrink-0 mt-1 black" />
                  <div className="d-flex align-items-center gap-2">
                    <p className="black ">
                      Timelink Connect Device Included ($74.99 Value)
                    </p>
                    <RiErrorWarningFill className="text-gray" size={20} />
                  </div>
                </div>
                <div className="d-flex black">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">
                    2 <strong>FREE</strong> Accumulating Marketing Credits{" "}
                    <strong>EACH</strong> Month ($150 Value)
                  </p>
                </div>
                {/* <div className="d-flex black">
                  <IoMdCheckmark
                    className="me-2 flex-shrink-0 black mt-1"

                  />
                  <p className="black">Customer Loyalty Program</p>
                </div> */}
                <div className="d-flex black">
                  <IoMdCheckmark className="me-2 flex-shrink-0 black mt-1" />
                  <p className="black">Survey Program</p>
                </div>

                <Link className="compare-link">Compare features.</Link>
              </div>
              {/* <div className=" text-center">
                <Button className=" flex-shrink-0  text-capitalize w-100 py-3 rounded-5 font-16">
                  Get started
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* =========== Marketing Campaigns ============== */}
      <div
        className="container rounded-5 py-4 my-lg-5 my-3"
        style={{
          background:
            "linear-gradient(180deg, #2C0099 0%, #29008D 62%, #000000 100%",
        }}
      >
        <div className="row justify-content-between py-5">
          <div className="col-lg-6 col-12  ps-0 d-flex  align-items-center  justify-content-center ">
            <img
              src={MarketingCampaign}
              style={{ width: "80%" }}
              className=" "
              alt="image not found"
            />
          </div>

          <div className="col-lg-6 col-12 mt-lg-0 mt-4 d-flex align-items-center text-lg-start text-center">
            <div>
              <p className="font-18 opacity-75 text-white">Add-on </p>
              <h1 className="  text-capitalize text-white">
                Marketing Campaigns{" "}
              </h1>
              <p className="font-18 text-white opacity-75 fw-normal ">
                Looking to reach more customers? Our platform provides your
                business with the ability to engage your desired audience more
                effectively. Marketing campaigns included in both essential and
                growth plans.
              </p>
              <div className="text-white container-fluid ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2 d-flex gap-2 align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className=" flex-shrink-0 "
                    />
                    <p className="mb-0">Advanced Analytics </p>
                    <p className="font-12 m-0 text-danger opacity-75 fw-medium ">
                      NEW
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2  d-flex align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className="me-2 flex-shrink-0 "
                    />
                    <p className="mb-0">Customisable Content</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2 d-flex gap-2 align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className=" flex-shrink-0 "
                    />
                    <p className="mb-0">Boost Engagement </p>
                    <p className="font-12 m-0 text-danger opacity-75 fw-medium ">
                      NEW
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2  d-flex align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className="me-2 flex-shrink-0 "
                    />
                    <p className="mb-0">Drive Sales</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2  d-flex align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className="me-2 flex-shrink-0 "
                    />
                    <p className="mb-0">Affordable Options </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-p-12 my-2  d-flex align-items-center">
                    <IoMdCheckmark
                      color="#7E75E6"
                      className="me-2 flex-shrink-0 "
                    />
                    <p className="mb-0">Scaleable Solutions</p>
                  </div>
                </div>
              </div>
              <Button
                type="button"
                className="btn-blue fw-normal mt-4 flex-shrink-0 w-auto p-2 rounded-5 font-16 px-4 text-capitalize "
                style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}
              >
                Get in touch today!{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* ============ new table ============ */}
      <br />
      <div className="container">
        <div className="text-center">
          <h1 className="font-48 fw-bold blue-text">Compare TimeLink plans </h1>
          <p className="text-secondary dm  font-18 blue-text fw-medium ">
            Discover the differences of each individual plan and learn more
            about our product
          </p>{" "}
        </div>
        <div className="redesign-table-main-section container overflow-x-auto  poppins my-5">
          <div className="redesign-table-inner">
            <div className="row justify-content-end my-5">
              <div className="col-8">
                <div className="container">
                  <div className="row">
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold font-26  ">Basic</h3>
                    </div>
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold  font-26 blue-text">Premium</h3>
                    </div>
                    <div className="col-4 text-center">
                      <h3 className=" fw-bold  font-26 ">Growth</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================ PRICING =================== */}
            <h5 className="fw-semibold mb-2">Pricing</h5>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1 ">
                  Price / month (billed annually){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-1" />
                  </span>
                </p>
                <ReactTooltip
                  id="my-tooltip-1"
                  place="right"
                  content="billed monthly"
                />
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$46</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$75</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$90</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Price / month (billed annually){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-2" />
                    <ReactTooltip
                      id="my-tooltip-2"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="billed monthly"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$50</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$80</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">$100</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= FEATURES ================ */}
            <h5 className="fw-semibold mt-5 mb-3">Features</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Digital Buzzer Feature{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-3" />
                    <ReactTooltip
                      id="my-tooltip-3"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Notifies customers digitally when their order is ready, enhancing the pickup experience and reducing wait times."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Marketing campaign{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-4" />
                    <ReactTooltip
                      id="my-tooltip-4"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Create and manage targeted marketing campaigns to engage customers and boost sales."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">1 per month</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0 ">2 per month</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Customer Loyalty Program{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-5" />
                    <ReactTooltip
                      id="my-tooltip-5"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      // content="Reward repeat customers with points or discounts, fostering customer loyalty and increasing return visits"
                      content="Coming Soon"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Survey Program{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-6" />
                    <ReactTooltip
                      id="my-tooltip-6"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Collect customer preferences through surveys to improve your services and understand customer needs"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Request Google-Review
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-7" />
                    <ReactTooltip
                      id="my-tooltip-7"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Easily prompt customers to leave a Google review, helping to build your online reputation
"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Request Feedback{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-8" />
                    <ReactTooltip
                      id="my-tooltip-8"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Gather direct feedback from customers to enhance your products and services"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Analytics & Insights{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-9" />
                    <ReactTooltip
                      id="my-tooltip-9"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Gain valuable insights into customer behaviour and sales performance with detailed analytics and reports"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">Basic</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">Comprehensive</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">Comprehensive</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Business Console{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-10" />
                    <ReactTooltip
                      id="my-tooltip-10"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Centralised hub for managing all aspects of your business operations and TimeLink services"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  SMS Notifications{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-11" />
                    <ReactTooltip
                      id="my-tooltip-11"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Automated SMS alerts to keep your customers informed about their order status in real-time"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Business Tablet Application{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-12" />
                    <ReactTooltip
                      id="my-tooltip-12"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Mobile application for managing orders, customer interactions, and business settings on the go"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Community{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-13" />
                    <ReactTooltip
                      id="my-tooltip-13"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Connect with other TimeLink users to share tips, best practices, and support (coming soon)."
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= STATS & REPORT ================ */}
            <h5 className="fw-semibold mt-5 mb-3">STATS & REPORTS</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  TimeLink Dashboard{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-14" />
                    <ReactTooltip
                      id="my-tooltip-14"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Overview of your business performance, including key metrics and insights"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Daily Stats{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-15" />
                    <ReactTooltip
                      id="my-tooltip-15"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Summary of daily business performance, including sales and customer interactions"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Busiest Period{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-16" />
                    <ReactTooltip
                      id="my-tooltip-16"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Identify peak hours and busiest times to optimise staff and resources"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Daily Orders{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-17" />
                    <ReactTooltip
                      id="my-tooltip-17"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Track the number of orders processed each day to monitor business activity"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Customer Demographics{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-18" />
                    <ReactTooltip
                      id="my-tooltip-18"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Insights into customer profiles, including age, gender, and location"
                    />{" "}
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Average wait-time{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-19" />
                    <ReactTooltip
                      id="my-tooltip-19"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Measure the average time customers wait for their orders to improve service efficiency
"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Age group{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-20" />
                    <ReactTooltip
                      id="my-tooltip-20"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Breakdown of customer age groups to better understand your audience"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Repeat Customer Rate{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-21" />
                    <ReactTooltip
                      id="my-tooltip-21"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Track the percentage of customers who return for repeat purchases"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Frequency of visits{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-22" />
                    <ReactTooltip
                      id="my-tooltip-22"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Monitor how often customers visit your business to gauge loyalty and satisfaction"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Clicks{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-23" />
                    <ReactTooltip
                      id="my-tooltip-23"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Monitor the click-through-rate for marketing campaigns"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Impressions{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-24" />
                    <ReactTooltip
                      id="my-tooltip-24"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="The total number of times your content is displayed, regardless of clicks"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Reach{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-25" />
                    <ReactTooltip
                      id="my-tooltip-25"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="The number of unique users who see your content"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Click-Through-Rate{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-26" />
                    <ReactTooltip
                      id="my-tooltip-26"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="The percentage of users who click on your content after seeing it"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Redeems{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-27" />
                    <ReactTooltip
                      id="my-tooltip-27"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Tracks how many promotional offers or loyalty rewards have been used by customers"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center"></div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= HARDWARE ================ */}
            <h5 className="fw-semibold mt-5 mb-3">HARDWARE</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  TimeLink Connect Device{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-28" />
                    <ReactTooltip
                      id="my-tooltip-28"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="The core hardware that enables seamless order tracking and customer notifications"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">$5 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Tablet (optional){" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-29" />
                    <ReactTooltip
                      id="my-tooltip-29"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="An optional device for managing orders and customer interactions on the go"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <p className="mb-0">$10 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <p className="mb-0">$5 p/m</p>
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================= SUPPORT ================ */}
            <h5 className="fw-semibold mt-5 mb-3">SUPPORT</h5>

            <div className="row g-0 p-3 bg-body-tertiary">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Priority support{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-30" />
                    <ReactTooltip
                      id="my-tooltip-30"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Fast-track assistance to resolve your issues quickly"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 p-3 ">
              <div className="col-4">
                <p className="mb-0 flex items-center gap-1">
                  Access to help centre{" "}
                  <span className="text-gray">
                    <AiOutlineQuestionCircle data-tooltip-id="my-tooltip-31" />
                    <ReactTooltip
                      id="my-tooltip-31"
                      place="right"
                      style={{ maxWidth: "400px" }}
                      content="Comprehensive resources and guides to help you make the most of TimeLink"
                    />
                  </span>
                </p>
              </div>
              <div className="col-8">
                <div className="container-fluid">
                  <div className="row g-0 ">
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                    <div className="col-4 text-center">
                      <IoMdCheckmark />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
      <br />
      {/* ========== FAQS ============ */}
      <section className="py-5" style={{ background: "#EFF0FF" }}>
        <div className="container mt-5">
          {/* <h2 className='text-center'>Pricing and Plan FAQ's</h2> */}
          <h1 className="font-66 fw-bold text-dark-blue text-center ">FAQs </h1>
          <div className="mt-8">
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3  before:hidden mt-3"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What is TimeLink and how does it work?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  TimeLink is a digital order notification system designed for
                  small-to-medium-sized businesses. It works by generating a
                  unique QR code and NFC link for each order, allowing customers
                  to track their order status in real-time through their mobile
                  devices.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  How do I integrate TimeLink with my Square POS system?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  To integrate TimeLink with Square POS, log into your TimeLink
                  admin panel, locate the 'Integrations' section, and click
                  'Connect' under the Square option. This will allow your Square
                  orders to automatically sync with TimeLink, generating
                  tracking codes for your customers.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What types of businesses can benefit from using TimeLink?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  TimeLink is ideal for cafes, pizza shops, burger shops, and
                  other small-to-medium-sized take-away businesses. It helps
                  streamline order management and enhances customer satisfaction
                  through real-time order tracking.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What kind of support does TimeLink offer?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  TimeLink offers priority support and access to a comprehensive
                  Help Centre. For immediate assistance, you can contact our
                  support team via email or through the Help Centre in your
                  admin panel.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  How do I monitor and analyse my business performance with
                  TimeLink?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  TimeLink provides detailed analytics and insights through the
                  admin panel. You can monitor daily stats, order counts,
                  customer demographics, and the effectiveness of your marketing
                  campaigns. These tools help you make informed business
                  decisions and optimise your operations.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  Is there a free trial available before committing to a
                  subscription?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  No, a free trial is not available because a TimeLink Connect
                  device is required to experience the complete benefits and use
                  of TimeLink. However, you will not be charged until the 7-day
                  trial period is over. After the 7 days, your payment will be
                  processed, and your device will be shipped out with express
                  shipping.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  Can I cancel my subscription at any time?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  Absolutely! If you are within your 7-day trial, you can cancel
                  your subscription at any time without any cancellation fees
                  through the business console. If you wish to cancel after the
                  trial period, please contact support@timelink.au. Please note
                  that if you cancel outside the trial period, you may be
                  charged for any unreturned TimeLink devices unless otherwise
                  arranged with your business specialist.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel8d-content"
                id="panel8d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  Are there any hidden fees or additional costs?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  No, TimeLink does not have any hidden fees. All costs are
                  clearly outlined in our pricing plans. Any additional features
                  or devices will be transparently billed based on your selected
                  plan.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel9-content"
                id="panel9d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  Is TimeLink compatible with my existing POS system?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  Currently, TimeLink integrates seamlessly with Square POS. We
                  are continually working on expanding our compatibility with
                  other POS systems. Stay tuned for updates in the
                  'Integrations' section of your admin panel.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel10-content"
                id="panel10d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  Can I upgrade or downgrade my plan at any time?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  Yes, you can upgrade or downgrade your plan at any time
                  through your admin panel. The changes will take effect
                  immediately, and your billing will be adjusted accordingly.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel11-content"
                id="panel11d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What if I need more devices or features than my current plan
                  offers?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  You can easily add more devices or features to your plan
                  through contacting TimeLink Support. If you find your business
                  growing and requiring more resources, upgrading your plan will
                  provide additional capabilities and support.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel12-content"
                id="panel12d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  How do I know if TimeLink is the right fit for my business?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  We recommend starting with the 7-day free trial to experience
                  how TimeLink can streamline your operations and enhance
                  customer engagement. Additionally, our support team is
                  available to answer any questions and help you maximise the
                  benefits of TimeLink.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What happens if I have technical issues or need assistance?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  Our dedicated support team is here to help. You can contact us
                  through the Help Centre, email support, or phone support
                  (depending on your plan). We are committed to ensuring your
                  experience with TimeLink is smooth and successful.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  What kind of support do I receive with my subscription?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  All subscription plans include access to our Help Centre and
                  priority email support. Higher-tier plans offer additional
                  support options, including phone support and dedicated account
                  managers.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-[#D1D1F7] !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<FaArrowDownLong size={23} />}
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <h5 className=" mb-0 text-dark-blue">
                  How secure is the data stored and shared with TimeLink?
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="font-18 text-dark-blue text-start">
                  TimeLink prioritises data security. We use advanced encryption
                  methods to ensure all data is securely stored and shared. Your
                  customer and business information is always protected.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </section>
     
      <section
        className=" py-5"
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 55%, #EFF0FF 55%, #E0EFFB 100%)",
        }}
      >
        <div className=" container ">
          <div className="bg-white p-5 rounded-5 shadow d-flex flex-wrap justify-content-between align-items-center">
            <h1 className="mb-0">TimeLink works wherever you do.</h1>
            <Button
              type="button"
              className="btn-blue fw-normal flex-shrink-0 w-auto mt-3 p-2 rounded-5 font-16 px-4 text-capitalize "
              style={{ outline: "4px solid rgba(85, 89, 221, 0.4)" }}
            >
              Get started for free{" "}
            </Button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PlansPricing;
