import React from 'react'

const Use_CasesCard = (props) => {
    return (
        <div className=' rounded-5 text-center h-100' style={{ backgroundColor: props.bgCard }}>
            <div className="p-4">

                <img src={props.img} className="w-100 rounded-4  "  alt="image not found " />
            </div>
            <div className=" p-2 pt-0">

                <p className='font-12 fw-medium text-dark-blue'>{props.title}</p>
                <p className='font-14' style={{color:"#1F357F"}}>{props.des}</p>
            </div>
        </div>
    )
}

export default Use_CasesCard
