import React from "react";
import CustomButton from "../CustomButton";
import { FaArrowRight } from "react-icons/fa6";

const SliderCard = ({
  border,
  shadow,
  bg,
  rounded,
  padding,
  addClass,
  handleClick,
  addclass,
  heading,
  headingClass,
  descriptionClass,
  description,
}) => {
  return (
    <div
      className={`${rounded ? rounded : "rounded-3xl"} ${
        shadow ? shadow : "shadow-[0_3px_9px_0_#07003B0A]"
      } ${bg ? bg : "bg-white"} ${border} ${
        padding ? padding : "p-6"
      } ${addClass} `}
    >
      <h5 className={`text-lg font-bold my-2 ${headingClass}`}>
        {heading && heading}
      </h5>
      <p className={`text-gray-400 font-medium ${descriptionClass}`}>
        {description && description}
      </p>
      <CustomButton
        bg padding={"p-0"}
        border textColor={"text-blue-300"}
        btntext={"Read More"}
        icon={<FaArrowRight className="ms-3"/>}
        addclass={addclass} handleClick={handleClick}
      />
    </div>
  );
};

export default SliderCard;
