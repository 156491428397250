import React from 'react';
import Navbar from '../../Components/Navbar';
import TestimonialCard from '../../Components/about/TestimonialCard';
import img from '../../assests/about/blogUser.png'
import joinImg from '../../assests/about/joinImg.png'
import { Button } from '@mui/material';
import Footer from '../../Components/about/Footer';
import ScrollToTop from '../../Components/ScrollToTop';

const Testimonials = () => {
    return (
        <><ScrollToTop/>
            <Navbar />
            <section className=' py-5 '>
                <div className="container inter">
                    <div className='text-center'>
                        <h1 className="font-48 fw-bold ">Testimonials</h1>
                        <p className="font-18 fw-medium" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                            Pharetra velit libero eros volutpat proin bibendum.
                        </p>
                    </div>
                    <div class="row justify-content-center  ">
                        <div class="col-lg-4 col-md-4 col-12">
                            <TestimonialCard img={img} head="Gillette" phara="Darrell Steward" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis,"/>
                            <TestimonialCard img={img} head="Louis Vuitton" phara="Jenny Wilson" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis velit leo lectus diam velit congue. Sagittis, at id viverra enim euismod. Non leo commodo, maecenas egestas pharetra."/>
                            <TestimonialCard img={img} head="Louis Vuitton" phara="Dianne Russell" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan."/>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <TestimonialCard img={img} head="eBay" phara="Theresa Webb" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan."/>
                            <TestimonialCard img={img} head="Mitsubishi" phara="Cameron Williamson" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis velit leo lectus diam velit congue. Sagittis, at id viverra enim euismod. Non leo commodo, maecenas egestas pharetra."/>
                            <TestimonialCard img={img} head="MasterCard" phara="Eleanor Pena" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis velit leo lectus diam velit congue. Sagittis, at id viverra enim euismod. Non leo commodo, maecenas egestas pharetra."/>

                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <TestimonialCard img={img} head="Facebook" phara="Marvin McKinney" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis velit leo lectus diam velit congue. Sagittis, at id viverra enim euismod. Non leo commodo, maecenas egestas pharetra."/>
                            <TestimonialCard img={img} head="McDonald's" phara="Kristin Watson" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan."/>
                            <TestimonialCard img={img} head="Apple" phara="Floyd Miles" pharaTwo="Sed pretium, in et neque sed. Magna euismod ac, gravida accumsan. Viverra diam non sagittis,"/>

                        </div>
                    </div>
                </div>
            </section>
            <section className=' pt-4 pb-5'>
                <div className="container">
                    <div className="row" style={{ backgroundColor: 'rgba(43, 43, 43, 0.1)', borderRadius: '15px'}}>
                        <div className="col-lg-6 col-12 pe-lg-0">
                            <div className='p-lg-4 p-md-4 p-3 h-100 d-flex align-items-center'>

                                <div className=' p-lg-2'>
                                    
                                    <h1 className="font-40 fw-bold text-capitalize" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                    Join a global team of change-makers.
                                    </h1>
                                    <p className="font-18 fw-medium " style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                    Viverra ut potenti aliquam feugiat dui imperdiet laoreet tempus sed. Elit cursus est lorem in est id nec. Quis diam posuere at nisl eget turpis sagittis nunc. Aliquet et ultrices purus, id. Sagittis erat nunc in parturient.                                    </p>
                                    <div>
                                        <Button className='btn-blue  px-3   rounded-5 font-16'>View Job Openings</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 pe-lg-0 px-0" style={{  borderTopRightRadius: '15px', borderBottomRightRadius: '15px'}}>
                            <img src={joinImg} className='w-100 h-100' alt="image not found" height={330} style={{ borderRadius: '15px'}} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>

        </>
    );
}

export default Testimonials;
