import React from 'react';
import Navbar from '../../Components/Navbar';
import '../../style/blog.scss'
import blogUser from '../../assests/about/blogUser.png'
import blog from '../../assests/about/blogImg.png'
import envelop from '../../assests/about/envelop.png'
import { Button } from '@mui/material';
import BlogCard from '../../Components/about/BlogCard';
import Footer from '../../Components/about/Footer';
import ScrollToTop from '../../Components/ScrollToTop';


const BlogPost = () => {
    return (
        <>
        <ScrollToTop/>
            <Navbar />
            <section className='pt-5 pb-5 '>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10 col-12">

                            <div className='align-items-center d-flex'>
                                <img src={blogUser} className='rounded-5 ' width={50} height={50} alt="image not found" />
                                <div className='ms-3'>
                                    <p className="font-16 fw-bold mb-0 text-uppercase">Alex Turner</p>
                                    <p className="font-16 mb-0">August 2, 2021</p>

                                </div>
                            </div>
                            <h1 className="font-66 fw-bold  mt-4">The Basics about Cryptocurrency</h1>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.
                            </p>
                        </div>
                        <div className="col-12 mt-lg-5 mt-md-5 mt-3 pb-lg-5 pb-md-5 pb-3">
                            <img src={blog} className='w-100' alt="image not found" />

                        </div>
                        <div className="col-lg-8 col-md-8 col-12">
                            <h1 className="font-42 fw-bold" style={{ color: 'rgba(0, 0, 0, 1)' }}>Learn what you need to know before you invest in a virtual currency</h1>
                            <p className="font-18 mt-4" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint <strong>occaecat cupidatat non </strong>proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut <u>labore et dolore</u> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <p className="font-28 fw-bold" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                How do I know how secure is my wallet?
                            </p>
                            <p className="font-18 mt-4" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint <strong>occaecat cupidatat non </strong> proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut <u>labore et dolore</u> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </div>
                        <div className="col-lg-10 col-md-10 col-12 mt-lg-5 mt-md-5 mt-3 pb-lg-5 pb-md-5 pb-3">
                            <img src={blog} className='w-100' alt="image not found" />

                        </div>
                        <div className="col-lg-8 col-md-8 col-12">
                            <p className="font-28 fw-bold" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Learn what you need to know before you invest in a virtual currency
                            </p>
                            <p className="font-18 mt-4" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint <strong>occaecat cupidatat non </strong>proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut <u>labore et dolore</u> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </div>
                        <div className="col-lg-10 col-md-10 col-12 my-4">
                            <div className='p-4 ' style={{ backgroundColor: 'rgba(43, 43, 43, 0.1)', borderRadius: '28px' }}>
                                <div className="container-fluid my-4">
                                    <div className="row justify-content-center py-3">
                                        <div className="col-9">

                                            <h5 className="font-22" style={{ color: 'rgba(0, 0, 0, 1)' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12">
                            <p className="font-28 fw-bold" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Learn what you need to know before you invest in a virtual currency
                            </p>
                            <p className="font-18 mt-4" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint <strong>occaecat cupidatat non </strong>proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <p className="font-18" style={{ color: 'rgba(0, 0, 0, 1)' }}>
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut <u>labore et dolore</u> magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            {/* ----------
                NEWSLISTER
                ----------- */}
            <section className='pt-5 pb-5 blog ' style={{ backgroundColor: 'rgba(3, 40, 238, 1)' }}>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-12 d-lg-flex d-md-flex text-lg-start text-md-start text-center align-items-center" >
                            <img src={envelop} alt="image not found" width={60} height={60} />
                            <h1 className='fw-bold font-42 ms-3 text-white'>Subscribe to our weekly newsletter!</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 d-lg-flex d-md-flex align-items-lg-center align-items-md-center">

                            <div className="input-group bg-white rounded-5">
                                <input type="email" className="form-control  p-3 font-16" placeholder="Enter email address" aria-describedby="basic-addon2" />
                                <span className="input-group-text border-0 d-lg-flex d-md-flex d-none" id="basic-addon2">
                                    <Button className='btn-blue  px-3   rounded-5 font-16'>Featured</Button>
                                </span>
                            </div>
                            <Button className='btn-blue w-100 p-3 mt-4 d-lg-none d-md-none d-block  rounded-5 font-16'>Featured</Button>

                        </div>
                    </div>
                </div>
            </section>
            {/* -----------
            latest
            ----------- */}
            <section className=' pt-5 pb-5 blog'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center">
                            <h1 className='fw-bold font-42'>Latest Posts</h1>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-center">
                            <Button className='btn-gray rounded-5 w-full p-3  font-16'>View all</Button>
                        </div>
                    </div>





                    <div className="row my-4">
                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                            <BlogCard img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                            <BlogCard img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mt-4">
                            <BlogCard img={blog} btn='product' head='The Basics about Cryptocurrency' phara='Lorem ipsum dolor sit ametero irseo, consectetur adipiscing elit. Scelerisque viverra donec diammeo.' user={blogUser} userName='Alex Turner' date='August 2, 2021' />
                        </div>


                    </div>


                </div>
            </section>
            <Footer />
        </>
    );
}

export default BlogPost;
