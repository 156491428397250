import React, { useEffect, useState } from "react";
import logo from "../../assests/Logo.png";
import "../../style/businessLogin/businesLogin.scss";
import { Col, Container, Row } from "reactstrap";
import { FaRegEnvelope, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import Swal from "sweetalert2";
const Login = () => {
  const navigate = useNavigate();
//   navigate("/");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("password");
  const webPassword = "Rene@123";
  // =========
  if (password.length > 0) {
    var stylepassword = {
      border: "1.5px solid rgba(37, 99, 235, 1)",
    };
  } else {
    var stylepassword = {
      border: "1.5px solid rgba(226, 232, 240, 1)",
    };
  }
  const toggleType = () => {
    if (type == "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length > 0) {
      if (password == webPassword) {
        sessionStorage.setItem("checkPassword", password);
        Swal.fire({
          icon: "success",
          text: "You are Successfully Login",
          showConfirmButton: false,
          timer: 1800,
        });
        navigate("/");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter password",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  // useEffect(()=>{
  //     navigate('/')
  // })
  return (
    <>
      <Container fluid className="h-screen flex items-center  ">
        <Row className=" flex justify-center items-center w-full">
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="text-center">
              <img src={logo} alt="image not found" className="my-3  mx-auto " width="150" />
            </div>
            <form
              className="login-form p-4 shadow  rounded-4 "
              onSubmit={handleSubmit}
            >
              <div className="inter ">
                <h4 className="font-24 fw-bold  text-dak-blue">Log In</h4>
              </div>
              <div className="input-group my-3 rounded-4" style={stylepassword}>
                <span
                  className="input-group-text border-0 ps-3"
                  id="basic-addon1"
                >
                  <MdLock size={20} color="rgba(100, 116, 139, 1)" />
                </span>
                <input
                  type={type}
                  required
                  className="form-control border-0 p-3 ps-0 rounded-4 "
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="input-group-text border-0 ps-3"
                  onClick={toggleType}
                  id="basic-addon1"
                >
                  {type == "password" ? (
                    <FaRegEye size={20} color="rgba(100, 116, 139, 1)" />
                  ) : (
                    <FaRegEyeSlash size={20} color="rgba(100, 116, 139, 1)" />
                  )}
                </span>
              </div>
              <Button
                type="submit"
                className="btn-blue rounded-4 inter px-3 p-3 w-100 fw-bold font-16 text-capitalize"
              >
                Continue
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
