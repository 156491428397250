import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../assests/Logo.png";
import "../style/navbar.scss";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { FaAngleDown } from "react-icons/fa6";
import { Drawer } from "@mui/material";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);

  const NavbarLink = () => {
    return (
      <ul className=" lg:flex ps-0 block mx-auto align-items-center mb-lg-0 mb-2 mt-lg-0 mt-3 mb-lg-0 text-capitalize">
        <li className="nav-list">
          <NavLink to="/" className="text-decoration-none font-14">
            Home
          </NavLink>
        </li>
        <li className="relative nav-list">
          <Menu
            menuButton={
              <MenuButton>
                <span
                  className={`font-medium flex gap-2 items-center text-sm ${
                    (pathname === "/feature/queue-management" ||
                      pathname === "/feature/guest-messages" ||
                      pathname === "/feature/square-pos-integration") &&
                    "blue-text"
                  }`}
                >
                  For Business <FaAngleDown />
                </span>
              </MenuButton>
            }
            menuClassName="!min-w-fit	text-sm w-fit"
            direction="bottom"
            align="center"
            position="anchor"
            viewScroll="auto"
            arrow={true}
            gap={5}
            shift={5}
            transition
          >
            <MenuItem
              onClick={() => navigate("/feature/queue-management")}
              className={`px-2 ${
                pathname === "/feature/queue-management" && "text-customBlue"
              }`}
            >
              Queue management
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/feature/guest-messages")}
              className={`px-2 ${
                pathname === "/feature/guest-messages" && "text-customBlue"
              }`}
            >
              Guest Messaging
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/feature/square-pos-integration")}
              className={`px-2 ${
                pathname === "/feature/square-pos-integration" && "text-customBlue"
              }`}
            >
             Square POS Integration
            </MenuItem>
          </Menu>
        </li>

        <li className="relative nav-list">
          <Menu
            menuButton={
              <MenuButton>
                <span
                  className={`font-medium flex gap-2 items-center font-14 ${
                    pathname === "/contact" && "blue-text"
                  }`}
                >
                  Company <FaAngleDown />
                </span>
              </MenuButton>
            }
            menuClassName="!min-w-fit text-sm	 w-fit"
            direction="bottom"
            align="center"
            position="anchor"
            viewScroll="auto"
            arrow={true}
            gap={5}
            shift={5}
            transition
          >
            <MenuItem className={"px-2"}>Help</MenuItem>
            <MenuItem className={"px-2"}>Support</MenuItem>
            <MenuItem
              onClick={() => navigate("/contact")}
              className={`px-2 ${
                location.pathname === "/contact" && "text-customBlue"
              }`}
            >
              Contact Us
            </MenuItem>
          </Menu>
        </li>
        <li className="nav-list">
          <NavLink to="/PlanPricing" className="text-decoration-none font-14">
            plans & pricing
          </NavLink>
        </li>
        <li className="nav-list">
          <NavLink
            to="/blog"
            className={`text-decoration-none font-14 ${
              pathname === "/blogPost" && "blue-text"
            }`}
          >
            Blog
          </NavLink>
        </li>
      

        {/* <li><NavLink to="/about" className='text-decoration-none '>About</NavLink></li> */}
        {/* <li><NavLink className='text-decoration-none ' >Tokens</NavLink></li> */}
        {/* <li className='my-0 my-1'><NavLink to="/contact" className='text-decoration-none font-14' >contact us</NavLink></li> */}
      </ul>
    );
  };
  return (
    <main className="bg-[#F6FBFF] poppins">
      <div
        className="text-center p-4 text-white"
        style={{
          background: " #1814F3",
        }}
      >
        <p className="mb-0">
          <strong>✨New✨ Features for Small businesses! </strong> Use TimeLink
          to boost your customer engagement and crush your goals! Learn more!
        </p>
      </div>
      <section className=" lg:block hidden">
        <header>
          <nav className="top-navbar  py-3 align-items-cente">
            <div className="container flex justify-between">
              <Link to="/">
                <img
                  className=""
                  src={logo}
                  alt="image not found"
                  width={150}
                />
              </Link>
              {NavbarLink()}
              <div className="d-flex align-items-cente">
                <Link
                  to="https://console.timelink.au/auth/signup"
                  className="text-decoration-none"
                >
                  <span
                    className=" py-2 px-4 text-dark font-15 fw-medium"
                    type="submit"
                  >
                    Register
                  </span>
                </Link>
                <Link to="https://console.timelink.au/auth/login">
                  {" "}
                  <button
                    className="bg-[#434343] text-white font-15 fw-medium ms-2 py-2 px-4 rounded-5"
                    type="submit"
                  >
                    Log In
                  </button>
                </Link>
              </div>
            </div>
          </nav>
        </header>
      </section>
      <section className="lg:hidden block p-3 bg-white">
        <div className="flex justify-between items-center">
          <Link to="/">
            <img className="" src={logo} alt="image not found" width={150} />
          </Link>
          <span
            className="bg-customBlue cursor-pointer w-9 h-9 flex justify-center items-center p-1 rounded-lg !text-white"
            onClick={() => setOpen(true)}
          >
            <HiOutlineMenuAlt3 size={25} />
          </span>
        </div>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <header>
            <nav className="top-navbar  py-3 align-items-cente">
              <div className="container">
                <div className="flex justify-between items-center">
                  <Link to="/">
                    <img
                      className=""
                      src={logo}
                      alt="image not found"
                      width={100}
                    />
                  </Link>
                  <RxCross1 size={20} onClick={() => setOpen(false)} />
                </div>
                {NavbarLink()}
                <div className="d-flex align-items-cente">
                  <Link
                    to="https://console.timelink.au/auth/signup"
                    className="text-decoration-none"
                  >
                    <span
                      className=" py-2 px-4 text-dark font-15 fw-medium"
                      type="submit"
                    >
                      Register
                    </span>
                  </Link>
                  <Link to="https://console.timelink.au/auth/login">
                    {" "}
                    <button
                      className="bg-[#434343] text-white font-15 fw-medium ms-2 py-2 px-4 rounded-5"
                      type="submit"
                    >
                      Log In
                    </button>
                  </Link>
                </div>
              </div>
            </nav>
          </header>
        </Drawer>
      </section>
    </main>
  );
};

export default Navbar;
