import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderCard from "../../../Components/feature/SliderCard";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const FeatureSlider = () => {
  const sliderRef = useRef(null);
  const customPaging = (i) => {
    return (
      <div className="flex items-center">
        <div
          className={`w-3 h-3 rounded-full bg-[#CBCDF5] ${
            i === sliderRef.current?.innerSlider?.state.currentSlide
              ? "!bg-cyan-500 w-4 h-4"
              : ""
          }`}
        ></div>
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    customPaging: customPaging, // Set customPaging for custom dot rendering
    appendDots: (dots) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}> {/* Align dots to start */}
          <ul style={{ margin: "0px" }}>{dots}</ul>
        </div>
      ),    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data = [
    {
      name: "Read More About Queue Management",
      content:
        "Managing the psychology of your customers as they wait in line is almost as important as focusing on reducing your queues.",
    },
    {
      name: "Read More About Queue Management",
      content:
        "Managing the psychology of your customers as they wait in line is almost as important as focusing on reducing your queues.",
    },
    {
      name: "Read More About Queue Management",
      content:
        "Managing the psychology of your customers as they wait in line is almost as important as focusing on reducing your queues.",
    },
    {
      name: "Read More About Queue Management",
      content:
        "Managing the psychology of your customers as they wait in line is almost as important as focusing on reducing your queues.",
    },
  ];
  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {data.map((value, index) => (
          <div key={index} className="p-3 py-4">
            <SliderCard heading={value.name} description={value.content} />
          </div>
        ))}
      </Slider>
      {/* <div className="flex justify-center gap-4 mt-4"> */}
      {/* <button
          onClick={() => sliderRef.current.slickPrev()}
          className="text-white bg-gray-400 w-10 h-10 rounded-full flex justify-center items-center"
        >
          <FaAngleLeft />
        </button> */}
      <button
        onClick={() => sliderRef.current.slickNext()}
        className="bg-emerald-300	 absolute right-28 top-1/2 z-10 text-white w-10 h-10 rounded-full flex justify-center items-center"
      >
        <FaAngleRight />
      </button>
      {/* </div> */}
    </>
  );
};

export default FeatureSlider;
