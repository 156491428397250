import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogCard = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div onClick={() => navigate(props.path)} style={{ borderRadius: '28px', backgroundColor: 'rgba(43, 43, 43, 0.1)', cursor: 'pointer', color: 'rgba(0, 0, 0, 1)' }}>
                <img src={props.img} alt="image not found" className='w-100' height={215}
                    style={{ borderTopLeftRadius: '28px', borderTopRightRadius: '28px' }} />
                <div className='px-4 pb-3'>

                    <Button className='btn-blue  px-3 rounded-5 font-16' style={{ transform: 'translate(0px, -20px)' }}>{props.btn}</Button>
                    <h4 className='font-22 fw-bold'>{props.head}</h4>
                    <p className="font-18">{props.phara}</p>
                    <hr className='border-0 mt-1 mb-1' style={{ height: '1.5px', backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
                    <div className='align-items-center d-flex'>
                        <img src={props.user} className='rounded-5 ' width={50} height={50} alt="image not found" />
                        <div className='ms-3'>
                            <p className="font-16 fw-bold mb-0 text-uppercase">{props.userName}</p>
                            <p className="font-16 mb-0">{props.date}</p>

                        </div>
                    </div>
                </div>


            </div>
        
                    
             
             
        </>
    );
}

export default BlogCard;
