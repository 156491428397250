import './App.scss';
import AppRoutes from './routes';
function App() {
  
  return (
    <>
      <AppRoutes/>
    </>
  );
}

export default App;
