import React from "react";
import "../../style/about.scss";
import Navbar from "../../Components/Navbar";
import DriveCards from "../../Components/about/DriveCards";
import Cardimg1 from "../../assests/about/DriveCard1.png";
import Cardimg2 from "../../assests/about/DriveCard2.png";
import Cardimg3 from "../../assests/about/DriveCard3.png";
import Cardimg4 from "../../assests/about/DriveCard4.png";
import planet from "../../assests/about/planet.png";
import team1 from "../../assests/about/Team1.png";
import team2 from "../../assests/about/Team2.png";
import team3 from "../../assests/about/Team3.png";
import Timeline from "../../Components/about/Timeline";
import OurTeam from "../../Components/about/OurTeam";
import brand1 from "../../assests/about/brand1.png";
import brand2 from "../../assests/about/brand2.png";
import brand3 from "../../assests/about/brand3.png";
import brand4 from "../../assests/about/brand4.png";
import brand5 from "../../assests/about/brand5.png";
import blog from "../../assests/about/blogImg.png";
import blogUser from "../../assests/about/blogUser.png";
import Footer from "../../Components/about/Footer";
import BlogCard from "../../Components/about/BlogCard";
import { Button } from "@mui/material";
import ScrollToTop from '../../Components/ScrollToTop';

const About = () => {
  return (
    <>
    <ScrollToTop/>
      <Navbar />
      <section className="">
        <div className="container">
          <div className="text-center pt-5">
            <h1 className="font-66 fw-bold">About Time Link</h1>
            <p className="font-18 mt-4">
              At TimeLink, we are driven by a passion to revolutionise the way
              businesses and customers interact. Our journey began with a simple
              idea: to bridge the gap between traditional buzzers and modern
              technology, providing a seamless order tracking experience.
              Founded in 2022, we embarked on extensive research and development
              to create an innovative solution that enhances customer
              satisfaction while streamlining operations for businesses.
              <br />
              <br />
              With the successful completion of our R&D in 2023, we proudly
              introduced TimeLink to Australia, offering a new era of efficient
              order notifications. Our commitment lies in continuously advancing
              our technology to empower businesses and delight customers, making
              every interaction a memorable one. Join us on this exciting
              journey as we redefine dining experiences with the power of
              TimeLink.
            </p>
          </div>
        </div>
        <br />
      </section>
      {/* ==============
      CARD
      ============= */}
      <section className="">
        <div className="container pt-4 pb-4">
          <h1 className="fw-bold font-42">What drives Time Link?</h1>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-12">
              <DriveCards
                img={Cardimg1}
                head="Innovation in customer engagement"
                phara="Pioneering innovative customer engagement solutions through technology."
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4">
              <DriveCards
                img={Cardimg2}
                head="data-driven business solutions"
                phara="Delivering business success with data-driven insights and actionable analytics."
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-12">
              <DriveCards
                img={Cardimg3}
                head="seamless user experience"
                phara="Delivering seamless user experience that connects businesses and customers effortlessly."
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4">
              <DriveCards
                img={Cardimg4}
                head="empowering business growth"
                phara="Empowering business growth by providing essential tools for modern digital communication."
              />
            </div>
          </div>
        </div>
        <br className="d-lg-block d-none" />
      </section>
      {/* ==============
      OYR MISSION
      ============= */}
      <section className="position-relative  pb-lg-4 pb-md-4 pb-3">
        <div className="container pt-lg-5 pt-md-5 pt-3">
          <div className="row">
            <div className="col-lg-6  col-12 ">
              <div>
                <h1 className="fw-bold font-42">Our mission</h1>
                <p className="font-18" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  At TimeLink, our mission is to empower small and medium-sized
                  businesses with the tools and technology they need to compete
                  in today's fast-paced market.
                  <br />
                  <br />
                  We firmly believe that every business deserves a fair chance
                  to succeed, irrespective of its size or budget constraints. We
                  are committed to helping businesses streamline their
                  operations and deliver exceptional customer experiences by
                  leveraging innovative solutions such as NFC and QR code
                  technology.
                  <br />
                  <br />
                  We're the first of our kind and we will never stop innovating.
                </p>
              </div>
              <div className="mt-lg-4 pt-3">
                <h1 className="fw-bold font-42 ">Our Purpose</h1>
                <p className="font-18" style={{ color: "rgba(0, 0, 0, 1)" }}>
                  Over the past three years, many businesses have faced
                  unprecedented challenges. We understand the struggles and the
                  resilience required to overcome them. Whether you run a café,
                  bar, pizza joint, or a burger place, the impact of events like
                  the COVID-19 pandemic has affected everyone.
                  <br />
                  <br />
                  As a business, we admire your strength and determination to
                  persevere, even when the odds were against you. We commend
                  your ability to adapt and thrive amidst adversity..
                </p>
              </div>
            </div>
            <div
              className="col-lg-6   col-12 mt-lg-0 mt-md-0 mt-4 d-lg-block d-none"
              style={{ height: "480px" }}
            >
              <img src={planet} alt="image not found" className="planet-img" height={480} />
            </div>
          </div>
        </div>
      </section>
      {/* ==============
      TIMELINE
      ============= */}
      <section className="bg-white pt-lg-5 pt-md-5 pt-0 pb-lg-5 pb-md-5 pb-2">
        <div className="container  pt-lg-5 pt-md-5 pt-0 pb-lg-5 pb-md-5 pb-2">
          <div className="row justify-content-between">
            <div className="col-lg-4  col-12">
              <h1 className="fw-bold font-42">Timeline</h1>
              <p className="font-18" style={{ color: "rgba(0, 0, 0, 1)" }}>
                At TimeLink, our journey has been marked by continuous
                innovation and an unwavering commitment to excellence. From our
                inception, we've been focused on providing cutting-edge B2B
                services, leveraging data to create meaningful connections
                between businesses and their customers. Our timeline tells a
                story of perseverance, evolution, and growth. Each milestone
                reflects our passion for delivering superior solutions, and as
                we look to the future, TimeLink is poised to redefine industry
                standards and shape the next chapter of digital interaction.
              </p>
            </div>
            <div className="col-lg-7 mt-lg-0 mt-4 col-12">
              <Timeline
                year="2022"
                head="An idea was born"
                phara="We noticed a gap in the market where customers (like myself) needed an easy order tracker and businesses wanted to ditch those bulky outdated buzzer systems."
              />
              <Timeline
                year="2022"
                head="Research & development"
                phara="Extensive research and development efforts commenced to bring TimeLink to life."
              />
              <Timeline
                year="2022"
                head="R&D was successfully finished"
                phara="Successfully completition of the research and development phase, bringing us closer to completing our vision."
              />
              <Timeline
                year="2022"
                head="announced to australia"
                phara="TimeLink was officially announced to businesses across Australia, marking a significant step for both TimeLink and businesses. "
              />
            </div>
          </div>
        </div>
      </section>
     
      {/* =============
      Footer
      ============= */}
      <Footer />
    </>
  );
};

export default About;
