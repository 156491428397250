import React from 'react';

const DriveCards = (props) => {
    return (
        <div className='p-4 py-5 h-100' style={{ backgroundColor: 'rgba(43, 43, 43, 0.1)',borderRadius:'28px' }}>
 
            <div className='d-lg-flex d-md-flex p-2'>

                <img src={props.img} width={47} height={47} alt="imge not found" />

                <div className="ms-lg-3 ms-md-3 mt-lg-0 mt-md-0 mt-3">
                    <p className="font-18 fw-bold text-uppercase ">{props.head}</p>
                    <p className="font-16 ">{props.phara}</p>
                </div>
            </div>


        </div>
    );
}

export default DriveCards;
