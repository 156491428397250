import emailjs from "@emailjs/browser";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button
} from "@mui/material";
import React, { useRef, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import envelop from "../../assests/about/ContactEnvelop.png";
import Footer from "../../Components/about/Footer";
import Navbar from "../../Components/Navbar";
import "../../style/contact.scss";

const Contact = () => {
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [showError, setShowError] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      name.length > 0 &&
      email.length > 0 &&
      company.length > 0 &&
      subject != "" &&
      message.length > 0
    ) {
      const service1Promise = emailjs.sendForm(
        "service_5hq5psr",
        "template_7rbg04m",
        form.current,
        "54oeHfEFxvv0ZOWaA"
      );
      const service2Promise = emailjs.sendForm(
        "service_yxivnbb",
        "template_u9cns7s",
        form.current,
        "54oeHfEFxvv0ZOWaA"
      );
      Promise.all([service1Promise, service2Promise])
        .then((results) => {
          console.log(results);

          // e.target.reset();
          setName("");
          setEmail("");
          setCompany("");
          setSubject("");
          setMessage("");
          // console.log('under then')
          Swal.fire({
            icon: "success",
            title: "Your Form has been successfully submited",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.error("Error sending emails:", error.message);
        });
      setValidationError(false);
      setShowError("");
    } else {
      setValidationError(true);
      setShowError("please fill up complete details");
    }
  };

  return (
    <>
      <Navbar />
      <section className=" py-lg-5 py-md-5 py-sm-4 py-3 contact-us">
        <div className="container mt-4">
          <h1 className="font-66 fw-bold blue-text">Get in touch</h1>
          <div className="row mt-4 justify-content-between">
            <form className="col-lg-7 col-12">
              <div className="container-fluid">
                <form ref={form} className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="form-label font-18  fw-bold text-uppercase"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control  font-18 border-0 rounded-5 p-3"
                        id="name"
                        placeholder="Full Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="form-label font-18  fw-bold text-uppercase"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control border-0 font-18 rounded-5 p-3"
                        id="email"
                        placeholder="email@example.com"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                    <div>
                      <label
                        htmlFor="Company"
                        className="form-label font-18  fw-bold text-uppercase"
                      >
                        Company
                      </label>
                      <input
                        type="text"
                        name="user_company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="form-control  font-18 border-0 rounded-5 p-3"
                        id="Company"
                        placeholder="Company Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                    <div>
                      <label
                        htmlFor="subject"
                        className="form-label font-18  fw-bold text-uppercase"
                      >
                        subject
                      </label>
                      <select
                        name="user_subject"
                        class="form-select border-0 font-18 rounded-5 p-3"
                        aria-label="Default select example"
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      >
                        <option selected value="">
                          How can we help?
                        </option>
                        <option value="Sales inquiry">Sales inquiry</option>
                        <option value=" Technical Support">
                          {" "}
                          Technical Support
                        </option>
                        <option value="Billing and Payments">
                          Billing and Payments
                        </option>
                        <option value="Account Assistance">
                          Account Assistance
                        </option>
                        <option value="Feedback and Suggestions">
                          Feedback and Suggestions
                        </option>
                        <option value="Report a Bug or Issue">
                          Report a Bug or Issue
                        </option>
                        <option value="Partnership Opportunities">
                          Partnership Opportunities
                        </option>
                        <option value="Marketing and Advertising">
                          Marketing and Advertising
                        </option>
                        <option value="Product Information">
                          Product Information
                        </option>
                        <option value="General Inquiry">General Inquiry</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div>
                      <label
                        htmlFor="msg"
                        className="form-label font-18  fw-bold text-uppercase"
                      >
                        {" "}
                        Message
                      </label>
                      <textarea
                        id="msg"
                        name="user_msg"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="form-control border-0 font-18 rounded-4 p-3"
                        cols="30"
                        rows="5"
                        placeholder="Your Message"
                        required
                      ></textarea>
                    </div>
                    {validationError && (
                      <div
                        className="alert alert-danger text-center mt-4"
                        role="alert"
                      >
                        {showError}
                      </div>
                    )}
                    <Button
                      type="button"
                      onClick={sendEmail}
                      className="btn-blue w-auto mt-4 p-3 rounded-5 font-16"
                    >
                      Send Message
                    </Button>
                  </div>
                </form>
              </div>
            </form>
            <div className="col-lg-4 col-12  mt-lg-0 mt-4 px-lg-0">
              <h4
                className="font-22 fw-bold"
                style={{ color: "rgba(0, 0, 0, 1)" }}
              >
                Want to reach us directly?
              </h4>
              <p className="font-18 " style={{ color: "rgba(0, 0, 0, 1)" }}>
                Got questions or want to learn more? <br />
                Contact us for all things TimeLink! Our team is here to help.
              </p>
              <div
                className="d-flex 
                            align-items-center"
              >
                <img
                  src={envelop}
                  width={38}
                  height={38}
                  alt="image not found"
                />
                <p className="font-18 fw-medium ms-2 mb-0">
                  <Link
                    to="mailto:support@timelink.au"
                    className="text-blue-500"
                    style={{ textDecoration: "none" }}
                  >
                    support@timelink.au
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============ faq */}
      <div className="container my-4" id="faqs-section">
        <h1 className="font-66 fw-bold blue-text text-center ">FAQ’s</h1>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12 contact-accordin">
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  How does TimeLink benefit businesses?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  TimeLink collects data and information from businesses and
                  their customers, using this information to enhance the overall
                  experience. Our system can also relay valuable customer data
                  back to the businesses, providing insights and analytics for
                  improved operations.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  How does TimeLink work?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Customers simply scan or tap the QR code on our Time Link
                  Connect device, input their phone number, and receive
                  real-time updates on their order's progress. No app download
                  required. It's a seamless way to enhance customer satisfaction
                  and streamline the ordering process. For businesses, it's just
                  as easy – choose a suitable subscription plan, receive your
                  Time Link Connect device, and input a reference ID for each
                  customer's order. Our system does the rest, notifying
                  customers and improving their overall experience.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  How does the Digital Stamp Loyalty Program work?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Our enhanced Digital Stamp Loyalty Program offers customers
                  rewards for their continued patronage. It's a seamless,
                  digital alternative to traditional stamp cards, offering
                  greater flexibility and personalised offers. You simply add
                  the desired reward to your customers order, this reward is
                  then synced to the users phone number and provides redemption
                  steps when necessary.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  What kind of data does TimeLink collect?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  We collect a range of data to better serve businesses and
                  consumers, including order details, customer preferences, and
                  feedback. All data collection is compliant with privacy
                  regulations, ensuring the utmost security and confidentiality.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel5" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Can i customise the notifications sent to customers?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  We collect a range of data to better serve businesses and
                  consumers, including order details, customer preferences, and
                  feedback. All data collection is compliant with privacy
                  regulations, ensuring the utmost security and confidentiality.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="shadow-none border-0 !bg-white   before:hidden mt-3"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel6" ? (
                    <FaMinus size={20} color="black" />
                  ) : (
                    <FaPlus size={20} color="black" />
                  )
                }
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <h4
                  className="fw-bold font-28 "
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Can TimeLink integrate with my existing POS system?
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <p
                  className="font-18 text-start"
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                >
                  Unfortunately at the moment TimeLink is unable to sync with
                  your POS system, however we are working extensively with major
                  companies to roll this feature out in the near future.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
