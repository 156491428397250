import React from 'react';
import point from '../../assests/about/point.png'
const Timeline = (props) => {
  return (
    <div className='pb-3 pt-3 d-flex mt-3' style={{borderBottom:'1px solid rgba(0, 0, 0, 1)'}}>
        <img src={point} width={24} height={24} alt="image not found" />
        <div className='ms-3'>
            <h3 className="font-28 fw-bold">{props.year}</h3>
            <p className="font-18 fw-bold text-uppercase"style={{ color: 'rgba(0, 0, 0, 1)' }}>{props.head}</p>
            <p className="font-18  "style={{ color: 'rgba(0, 0, 0, 1)' }}>{props.phara}</p>
        </div>
      
    </div>
  );
}

export default Timeline;
