import React from 'react';

const HomeCards = (props) => {
    return (
        <>
            <div className={`p-4 h-50 text-center mt-${props.marginTop}`} style={{ backgroundColor: '#DCEBF7', borderRadius: '28px' }}>

                <div className=''>

                    {/* <img src={props.img} width={47} height={47} alt="imge not found" /> */}

                    <div className="mt-2">
                        <p className="font-18 fw-bold text-uppercase mb-1">{props.head}</p>
                        <p className="font-16 mt-3">{props.phara}</p>
                    </div>
                </div>


            </div>
        </>
    );
}

export default HomeCards;
